*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #6d6d6d;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0f47e0;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0432b1;
  }


  
#room {
  background-color: #0d0812;
  height: 100vh;
  width: 100%;
}

.room-header {
  width: 100%;
  height: 50px;
  background-color: #fff;
}

.room-header h2 {
  font-size: 1.2rem;
  padding: 13px;
}

.room-cont {
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
}

.call-room {
  height: 100%;
  width: 500px;
}

.chat-room {
  height: 100%;
  background-color: #fff;
  width: calc(100% - 500px);
}

.video-view {
  position: relative;
  height: 88%;
  width: 50%;
  padding-top: 30px;
}

.remote-view {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-color: #494949;
}

.local-view {
  height: 170px;
  width: 150px;
  position: absolute;
  border-radius: 10px;
  background-color: #8a8a8a;
  bottom: 10px;
  right: 10px;
}

.video-controls {
  position: absolute;
  bottom: 20px;
}

.control-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #17101f;
}

.control-button.close {
  background-color: rgb(194, 0, 0);
}

.c-bt-icon {
  font-size: 1.4rem;
  position: relative;
  right: 1px;
  color: #fff;
}

/* Chat Part */

.chat-header {
  height: 50px;
  width: 100%;
  display: flex;
  text-align: left;
  border-top: 1px solid #6d6d6d;
  border-bottom: 1px solid #6d6d6d;
}

.chat-header div {
  cursor: pointer;
  width: 50%;
  height: 50px;
  padding: 10px;
}

.chat-header .active {
  border-bottom: 5px solid #2d56a9;
}

#chatCont {
  overflow: auto;
}

.chat-cont {
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.chat-mobile-cont {
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    position: absolute;
    width: 100%;
    height: calc(100% - 100px);
    flex-direction: column;
  }

.chat-view {
  overflow: auto;
  height: 86%;
}

.chat-input {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
}

.input-cont {
  display: flex;
  align-items: center;
  width: 97%;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.input-cont input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
  font-size: 0.9rem;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.message-opt {
  display: flex;
}

.message-opt * {
  font-size: 1.5rem;
  margin-right: 15px;
}

.chat-bubble {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  color: #fff;
  font-weight: 500;
}

.chat-bubble p {
  font-size: 0.9rem;
  padding: 10px 10px;
  border-radius: 10px;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.chat-bubble p span {
  position: relative;
  top: 8px;
  left: 4px;
  font-size: 0.65rem;
  font-weight: 400;
}

/* Prescrition */

div.scrollmenu {
    overflow: auto;
    margin: 0 10px;
    margin-top: -10px;
    white-space: nowrap;
    position: relative;
    z-index: 1;
  }

 
  div.scrollmenu span {
    display: inline-block;
    color: #2d56a9;
    text-align: center;
    padding: 14px 14px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
  }

  div.scrollmenu span:first-child{
    padding-left: 0;
  }
  
  
  div.scrollmenu span:hover {
    color: #10295a;
  }

.prescription-header {
  display: flex;
  padding-top: 10px;
  border-top: 1px solid #212121;
}

.pres-patient-det {
  width: 100%;
}

.pres-patient-photo-cont {
  width: 20%;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}

.pres-patient-photo {
  height: 130px;
  width: 130px;
  background-color: rgb(109, 109, 109);
}

.pres-patient-det {
  margin-left: 10px;
}


.previous-visits {
  height: 60px;
  width: 100%;
  padding: 10px;
}


.prescription-cont-outside{
  width: 0;
}

.prescription-cont-outside.show{
  width: 80%;
}

.previous-visits select {
  height: 100%;
  width: 100%;
  border: 1px solid #212121;
  outline: none;
  background-color: #fff;
  font-size: 1rem;
  padding: 0 10px;
  border-radius: 8px;
}

.pres-accordian {
  margin-bottom: 10px;
}

.pres-accordian-top {
  width: 100%;
  background-color: #2d56a9;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.pres-accordian-top p {
  position: relative;
  top: 6px;
  padding-left: 5px;
}

.accordian-icon {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.accordian-icon.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.pres-accordian-bottom {
  height: 0;
  overflow: hidden;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background-color: #cadcff;
}

.pres-accordian-bottom.show {
  height: auto;
}


#pres-acc-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 98%;
    margin-bottom: 10px;
  }
  
  #pres-acc-table td, #pres-acc-table th {
    border: 1px solid #2d56a9;
    padding: 8px;
  }
  
  #pres-acc-table tr:nth-child(even){background-color: #f2f2f2;}
  
  #pres-acc-table tr:hover {background-color: #ddd;}
  
  #pres-acc-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2d56a9;
    color: white;
  }


.a {
  display: flex;
  justify-content: center;
}

.a textarea {
  padding: 5px;
  outline: none;
  position: relative;
  bottom: 10px;
}

.printCheck{
    height: 20px;
    width: 20px;
}

.inves select {
  padding: 8px 5px;
  outline: none;
  bottom: 15px;
  width: 95%;
  margin: 5px;
  border-radius: 5px;
}

.inves input{
    padding: 6.5px 5px;
  outline: none;
  bottom: 15px;
  width: 95%;
  margin: 5px;
  border-radius: 5px;
}
