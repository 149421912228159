#admin_panel{
    width: 100%;
    min-height: 100vh;
    display: flex;
}

#side_bar{
    width: 280px;
    text-overflow: hidden;
    transition: .5s ease-in-out;
    justify-content: left;
    background-color: #2d55a8;
    height: 100vh;
    position: fixed;
    overflow: auto;
    z-index: 2;
}

.side_bar_header_flex{
    width: 100%;
    overflow: hidden;
    height: 65px;
    display: flex;
}



#change-p-i{ 
    width: 100%;
    display: none;
    padding: 0 10px;
}

#change-p-i input{
    width: 100%;
    margin-bottom: 15px;
    height: 45px;
    padding: 5px;
}



.c-p-btn{
    background-color: #2d55a8;
    color: white;
    border: none;
}

.side_bar_header{
    width: 100%;
    overflow: hidden;
    background-color: #103172;
}

.profile_icon{
    height: 50px;
    position: relative;
    top: 14px;
    left: 8px;
    border-radius: 50%;
    width: 50px;
    overflow: hidden;
    background-color: #413f4b;
}

.profile_icon .icon{
    position: relative;
    top: 10px;
    left: 11.3px;
    color: white;
    font-size: 1.6rem;
}

.profile_det{
    position: relative;
    top: 18px;
    left: 15px;
}

.profile_det h4{
    font-size: 1.2rem;
    color: white;
}

.profile_det span{
    color: #d1d8e7;
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    bottom: 10px;
    white-space: nowrap;
    left:  2px;
    cursor: pointer;
}

.profile_det span:hover{
    color: #9095a0;
}

.side_bar_content .list.active, .side_bar_content .list:hover{
    background-color: #fff;
}

.side_bar_content .list{
    color: #2d55a8;
    cursor: pointer;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-right: 15px;
    height: 40px;
    overflow: hidden;
    background-color: #cacaca;
    margin-bottom: 15px;
}

.side_bar_content .list h5{
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    text-overflow: hidden;
    top: 10px;
    left: 15px;
}

/* panel */
.panel_bar{
    width: calc(100% - 280px);
    justify-content: right;
    position: absolute;
    right: 0;
}

.panel{
    padding: 35px;
}

.panel h2{
    
}


.panelHead .p-head-icon{
    display: none;
}


.panel_cont input[type=radio]{
    height: 20px;
    width: 20px;    
}




@media screen and (max-width: 800px) {
    #side_bar{
        width: 0;
        position: absolute;
        min-height: 100vh;
        z-index: 1;
    }

    .panel{
        padding: 0;
        width: 100%;
    }
    
    .panel_cont{
        padding: 14px;
    }

    .panel_bar{
        width: 100%;
    }

    .panelHead{
        background-color: #2b273e;
        padding: 14px;
        color: white;
        display: flex;
        width: 100%;
        flex: 1
    }

    .panelHead .p-head-icon{
        font-size: 1.9rem;
        display: block;
        margin-right: 15px;
        position: relative;
        top: 1.5px;
    }


    .edit-form{
        padding: 0
    }


   

    .panelHead h4{
        font-weight: 600;
        position: relative;
        top: 4px;
    }

    .profile_icon{
        background-color: transparent;
    }

    
}