
.cont-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2b273e;
    color: white;
  }

  .cont-table table {
    overflow: auto;
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .cont-table td, .cont-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .cont-table tr:nth-child(even){background-color: #f2f2f2;}
  
  .cont-table tr:hover {background-color: #ddd;}

  .cont-table span{
    font-weight: 600;
    color: darkorange;
    cursor: pointer;
  }

  .edit-form label{
    width: 100%;
    font-weight: 600;
  }
  
  .edit-form input{
    width: 100%;
    padding: 5px;
    height: 45px;
    border: 1px solid #2d55a8;
    border-radius: 5px;
  }

  .edit-form select{
    width: 100%;
    padding: 5px;
    height: 45px;
    border: 1px solid #2d55a8;
    border-radius: 5px;
  }


  .photo_col{
    width: 100px;
    height: 100px;
    background-color: #f2f2f2;
  }

  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(odd){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2d55a8;
    color: white;
  }

  .imp{
    color: 'red !important'
}

.foo{
  background-color: #2d55a8;
  color: white;
  table-layout: fixed;
  width: 100%;
  
}



.transaction-id-column {
  width: 60px !important;
}