#auth{
    display: flex;
    height: 100vh;
    width: 100%;
}

.left{
    width: 350px;
    justify-content: left;
    background-color: #2d55a8;
    height: 100%;
    text-align: center;
}

.left-cont{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.left h1{
    color: white    ;
    font-weight: bolder;
    margin-top: 10px;
    font-size: 2.3rem;
}

.left img{
    width: 150px;
}

.left p{
    color: #bbbbbb;
    font-size: 0.9rem;
}

.right{
    width: calc(100% - 300px);
    justify-content: left;
    height: 100%;
}


.right h4{
    font-weight: 600;
    font-size: 1.8rem;
}

.right-cont{
    position: relative;
    padding: 15%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.mb-logo{
    display: none;
    text-align: center;
}

.mb-logo img{
    height:120px !important;
    width: 180px !important;
}

.right-cont img{
    width: 150px;
    height: 100px;
}

.utype-r-mb{
    display: flex;
    margin-top: 15px;
}

.utype-r-mb label{
    width: 125px !important;
}

.utype-r-mb span{
    position: relative;
    bottom: 4px;
}

.utype-r-mb input{
    width: 20px !important;
    margin-right: 5px;
    height: 20px !important;
}

/* .utype-r{
    position: fixed;
    bottom: 10px;
    left: 50px;
} */

.utype-r span{
    color: #fff;
}

.utype-r label{
    padding: 0px 15px;
    color: #fff;
}

.utype-r input{
    margin-right: 3px;
}

.auth-form-cont{
}


.auth-form-cont label, .auth-form-cont input{
    width: 100%;
    height: 45px;
}

.auth-form-cont input{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    outline: none;
}

.auth-form-cont input::placeholder{
    font-size: .9rem;
}

.auth-form-cont label{
    font-weight: 600;
}


.auth-btn{
    margin-top: 15px;
    border: none !important;
    color: white;
    transition: 0.2s ease-in-out;
    background-color: #2d55a8;
}

.auth-btn:hover{
    background-color: #1a3770;
}



@media screen and (max-width: 850px){
    .left{
        width: 0;
        display: none;
    }

    .right{
        width: 100%;
    }

    .right-cont{
        padding: 40px;
    }

    .mb-logo{
        display: block;
    }
}

@media screen and (max-width: 600px){

    .right-cont{
        padding: 15px;
    }
}